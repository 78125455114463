import { Box, InputAdornment, TextField } from '@mui/material'
import { useReducer } from 'react'
import { softShadow } from '~/common/commonStyles'

type Props = {
    name: string,
    label: string,
    type: string,
    value: string,
    icon: React.ReactNode
    error: string
    placeHolder?: string
}

const inputReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'CHANGE':
            return {
                ...state,
                value: action.val,
                isValid: true
            }
        case 'TOUCH':
            return {
                ...state,
                isTouched: true
            }
        default:
            return state
    }
}

function LoginTextField({ name, label, type = "text", value, icon, error, placeHolder }: Props) {

    const [inputState, dispatch] = useReducer(inputReducer, {
        value: value,
        isValid: true,
        isTouched: false
    })

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'CHANGE', val: event.target.value })
    }

    const touchHandler = () => {
        dispatch({ type: 'TOUCH' })
    }

    return (
        <Box sx={{ ...softShadow, px: 2, py: 1 }}>
            <TextField
                key={name}
                name={name}
                label={label}
                type={type}
                value={inputState.value}
                onChange={changeHandler}
                onBlur={touchHandler}
                placeholder={placeHolder}
                variant="standard"
                fullWidth
                error={error !== ''}
                helperText={error}
                inputProps={{ style: { padding: 0 } }}
                InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                        <InputAdornment position="start" >
                            {icon}
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    )
}

export default LoginTextField